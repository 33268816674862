'use client';

import type { ReactNode } from 'react';
import Header from './Header';
const Layout = ({
  children
}: {
  children: ReactNode;
}) => {
  return <div className="min-h-screen flex flex-col" data-sentry-component="Layout" data-sentry-source-file="index.tsx">
      <div className="pb-[69px] lg:pb-0">
        <Header data-sentry-element="Header" data-sentry-source-file="index.tsx" />
        <main className="flex-1 flex">{children}</main>
      </div>
    </div>;
};
export default Layout;