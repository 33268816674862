'use client';

import { Moon, Sun } from 'lucide-react';
import { useTheme } from 'next-themes';
import { Button } from '@/components/ui/button';
export default function ModeToggle() {
  const {
    theme,
    setTheme
  } = useTheme();
  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };
  return <Button variant="outline" size="icon" onClick={toggleTheme} data-sentry-element="Button" data-sentry-component="ModeToggle" data-sentry-source-file="ModeToggle.tsx">
      <Sun className="dark:hidden" data-sentry-element="Sun" data-sentry-source-file="ModeToggle.tsx" />
      <Moon className="hidden dark:block" data-sentry-element="Moon" data-sentry-source-file="ModeToggle.tsx" />
      <span className="sr-only">Toggle theme</span>
    </Button>;
}