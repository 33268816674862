'use client';

import { Share, PlusSquare } from 'lucide-react';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/components/ui/dialog';
const InstallDialog = () => {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    // Check if we're on mobile and not in standalone mode
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
    if (isMobile && !isStandalone) {
      setIsOpen(true);
    }
  }, []);
  return <Dialog open={isOpen} onOpenChange={setIsOpen} data-sentry-element="Dialog" data-sentry-component="InstallDialog" data-sentry-source-file="InstallDialog.tsx">
      <DialogContent className="sm:max-w-[425px] mx-auto" autoFocus={false} data-sentry-element="DialogContent" data-sentry-source-file="InstallDialog.tsx">
        <div className="mx-auto w-full max-w-sm">
          <DialogHeader className="mb-6" data-sentry-element="DialogHeader" data-sentry-source-file="InstallDialog.tsx">
            <div className="my-4 flex justify-center">
              <Image src="/icons/icon-192x192.png" alt="Foil App Icon" width={72} height={72} className="rounded-2xl border border-border shadow-lg" data-sentry-element="Image" data-sentry-source-file="InstallDialog.tsx" />
            </div>
            <DialogTitle className="text-center text-2xl" data-sentry-element="DialogTitle" data-sentry-source-file="InstallDialog.tsx">
              Install Foil
            </DialogTitle>
            <DialogDescription className="max-w-[260px] mx-auto" data-sentry-element="DialogDescription" data-sentry-source-file="InstallDialog.tsx">
              Add the app to your home screen for{' '}
              <strong>one tap to gas price charts & analytics</strong>.
            </DialogDescription>
          </DialogHeader>
          <div className="space-y-4 rounded-lg bg-muted px-4 py-6 text-center">
            <div className="space-y-2">
              <p>
                Tap the{' '}
                <span className="mx-0.5 inline-flex translate-y-[3px] items-center">
                  <Share className="h-5 w-5" data-sentry-element="Share" data-sentry-source-file="InstallDialog.tsx" />
                </span>{' '}
                icon in your browser
              </p>
            </div>
            <div className="space-y-2">
              <p>
                Select{' '}
                <span className="mx-0.5 inline-flex translate-y-[3px] items-center">
                  <PlusSquare className="h-5 w-5" data-sentry-element="PlusSquare" data-sentry-source-file="InstallDialog.tsx" />
                </span>{' '}
                Add to Home Screen
              </p>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>;
};
export default InstallDialog;