export const RESOURCES = [
  {
    id: 'ethereum-gas',
    name: 'Ethereum Gas',
    iconPath: '/eth.svg',
  },
  {
    id: 'ethereum-blobspace',
    name: 'Ethereum Blobspace',
    iconPath: '/eth-blob.svg',
  },
  {
    id: 'celestia-blobspace',
    name: 'Celestia Blobspace',
    iconPath: '/tia.svg',
  },
];
